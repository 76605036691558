import React from 'react';

import { ControlGroup, Spinner, Button, Input, InputGroup } from '@blueprintjs/core'

import Uploads from '../Utilities/Uploads';

class Paste extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            title: ''
        }

        this.onPaste = this.onPaste.bind(this);
    }

    onPaste(e) {
        e.preventDefault();
        for (let i = 0; i < e.clipboardData.items.length; ++i) {
            let item = e.clipboardData.items[i];
            if (item.kind === 'file') {
                this.props.hide();
                if (this.props.onUpload) this.props.onUpload(item.getAsFile())
                else Uploads.Add([item.getAsFile()]);

            }
        }
    }

    render() {
        return (
            <>
                <ControlGroup vertical>
                    {this.props.title && (
                        <InputGroup
                            value={this.state.title}
                            onChange={(e) => {
                                this.setState({ title: e.target.value })
                                if (this.props.uploadTitle) this.props.uploadTitle(e.target.value);
                            }}
                            placeholder={'Title'}
                            large
                            fill
                        />
                    )}
                    <input class="bp5-input bp5-large bp5-fill" type="text" placeholder="Paste here" onPaste={this.onPaste} dir="auto" />
                    {!this.props.noHide && (
                        <Button icon={'cross'} style={{ paddingLeft: 15, paddingRight: 15 }} onClick={this.props.hide} />
                    )}
                </ControlGroup>
            </>
        )
    }
}

export default Paste;