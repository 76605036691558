import React, { useState, useEffect } from 'react';

import { Dialog, Classes, ControlGroup, InputGroup, FormGroup, Button, MenuItem, TextArea } from '@blueprintjs/core'
import { Select } from "@blueprintjs/select";

import API from '../Utilities/API';

import MediaButton from '../Media/Button';

export default (props) => {

    console.log('EditFolder', props);

    const TypeSelect = Select.ofType();

    const [load, setLoad] = useState(0);
    const [loading, setLoading] = useState(false);
    const [photoID, setPhotoID] = useState(props.folder && props.folder.photo_id ? props.folder.photo_id : null);
    const [photoPath, setPhotoPath] = useState(props.folder && props.folder.file_path ? props.folder.file_path : null);
    const [title, setTitle] = useState(props.folder && props.folder.title ? props.folder.title : '');
    const [description, setDescription] = useState(props.folder && props.folder.description ? props.folder.description : '');
    const [folder, setFolder] = useState(props.folder.parentFolder && props.folder.parentFolder.id ? {
        id: props.folder.parentFolder.id,
        title: props.folder.parentFolder.title
    } : {
        id: '0',
        title: 'Uncategorized'
    });

    const [folders, setFolders] = useState([]);
    useEffect(() => {
        API.CasesFolders().then((result) => {
            if (result && result.data && result.data.Data) {
                const folds = result.data.Data.folders.filter((entry) => (entry.parent == '0'));
                setFolders([
                    ...folds,
                    {
                        id: '0',
                        title: 'Uncategorized'
                    }
                ])
            }
        })
    }, [load]);

    const filter_do = (query, data, i, exact) => {
        let title = '';
        if (data && data.name) title = data.name.toLowerCase();
        if (data && data.title) title = data.title.toLowerCase();
        query = query.toLowerCase();
        if (exact) return title === query;
        else return title.length == 0 || title.indexOf(query) > -1;
    }

    const DeleteFolder = () => {
        if (window.confirm('Delete folder and all subfolders?')) {
            props.onDelete && props.onDelete(props.folder.id);
        }
    }

    if (props.folder && props.folder.user_id && props.folder.user_id != window.nd.User.id) return null;

    return (
        <>
            <Dialog
                isOpen={true}
                onClose={() => {
                    props.onClose();
                }}
                autoFocus={true}
                canEscapeKeyClose={true}
                canOutsideClickClose={true}
                enforceFocus={true}
                usePortal={true}
                className={Classes.DARK}
                style={{
                    width: 700
                }}
            >
                <div className={Classes.DIALOG_BODY}>
                    <div style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row'
                    }}>
                        <div style={{
                            flexShrink: 0,
                            flexGrow: 0,
                            flexBasis: 200,
                            padding: 20
                        }}>
                            <MediaButton
                                addToCase={(file_id, title, data) => {
                                    setPhotoID(file_id);
                                    setPhotoPath(data.fileData.short_location);
                                }}
                                text={'Select image'}
                                fill
                                large
                            />
                            {photoPath && photoPath.length > 0 ? (
                                <div style={{ marginTop: 20 }}>
                                    <img src={`//static.pub.cesno.org${photoPath}`} style={{
                                        width: '100%'
                                    }} />
                                </div>
                            ) : null}

                        </div>
                        <div style={{
                            flex: 1,
                            flexGrow: 1
                        }}>
                            <p>
                                <ControlGroup>
                                    <FormGroup label={'Folder title'} style={{ width: '100%' }}>
                                        <InputGroup
                                            value={title}
                                            onChange={(v) => {
                                                setTitle(v.target.value);
                                            }}
                                            placeholder={'Folder title'}
                                        />
                                    </FormGroup>
                                </ControlGroup>
                            </p>
                            <p>
                                <ControlGroup>
                                    <FormGroup label={'Folder description'} style={{ width: '100%' }}>
                                        <TextArea
                                            value={description}
                                            fill
                                            style={{ height: 300 }}
                                            onChange={(v) => {
                                                setDescription(v.target.value);
                                            }}
                                            placeholder={'Folder description'}
                                        />
                                    </FormGroup>
                                </ControlGroup>
                            </p>
                            <p>
                                <FormGroup label={'Folder'} style={{ width: '100%' }}>
                                    <ControlGroup>
                                        <TypeSelect
                                            fill
                                            items={folders.flatMap((folder) => {
                                                let r = [];
                                                r.push(folder);
                                                if (folder.children) {
                                                    folder.children.forEach((child) => {
                                                        r.push({
                                                            ...child,
                                                            title: '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- ' + child.title
                                                        });
                                                    });
                                                }
                                                return r;
                                            })}
                                            itemPredicate={filter_do}
                                            itemRenderer={(c, { handleClick }) => {
                                                return <MenuItem
                                                    text={<div dangerouslySetInnerHTML={{ __html: c ? c.title : '' }}></div>}
                                                    onClick={handleClick}
                                                    style={props.style}
                                                />
                                            }}
                                            onItemSelect={(item) => {
                                                setFolder(item);
                                            }}
                                            menuProps={{ className: 'limit-popup' }}
                                        >
                                            <Button style={props.style} fill>
                                                <span dangerouslySetInnerHTML={{ __html: folder.title }} />
                                            </Button>
                                        </TypeSelect>
                                    </ControlGroup>
                                </FormGroup>
                            </p>
                        </div>
                    </div>
                </div>
                <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <div style={{
                            display: 'flex',
                            width: '100%',
                            justifyContent: 'space-between'
                        }}>
                            <div style={{
                                grow: 1
                            }}>
                                {props.folder && props.folder.id ? (
                                    <Button intent={'danger'} icon={'trash'} style={{ marginLeft: 0 }} onClick={DeleteFolder}>Delete folder</Button>
                                ) : null}
                            </div>
                            <div style={{
                                grow: 1,
                                display: 'flex',
                                flexDirection: 'row-reverse'
                            }}>
                                <Button onClick={() => {
                                    props.onClose();
                                }}>Close</Button>

                                <Button
                                    loading={loading}
                                    intent={'primary'}
                                    icon={'tick'}
                                    onClick={() => {
                                        setLoading(true);
                                        props.onSave && props.onSave({
                                            title,
                                            description,
                                            parent: folder.id,
                                            photo_id: photoID
                                        });
                                    }}
                                >
                                    Save
                                </Button>

                            </div>
                        </div>

                    </div>
                </div>

            </Dialog>
        </>
    )

}