import { useState, useEffect } from 'react'

import { Button, Tag, Dialog, Classes, Tree, Checkbox } from "@blueprintjs/core";
import API from '../Utilities/API';
import { ExpandLessRounded, RepeatOneSharp } from '@material-ui/icons';

import EditFolder from './EditFolder';


export default function FoldersButton(props) {

    const [folders, setFolders] = useState([])
    const [open, setOpen] = useState(false);
    const [saving, setSaving] = useState(false);
    const [timeLoaded, setTimeLoaded] = useState(0);
    const [expanded, setExpanded] = useState([]);
    const [selected, setSelected] = useState([]);
    const [showEdit, setShowEdit] = useState(false);

    const loadFolders = () => {
        API.CasesFolders(props.content_id).then((response) => {
            setFolders(response.data.Data ?? []);
            setSelected(response.data.Data.content_folders ?? [])
            setTimeLoaded(new Date().getTime());

            if (response.data.Data.content_folders && response.data.Data.content_folders.length > 0) {
                const newSel = [];
                response.data.Data.folders
                    .filter((folder) => (folder.parent == '0'))
                    .forEach((folder) => {
                        response.data.Data.folders
                            .filter((sub_folder) => (sub_folder.parent == folder.id))
                            .forEach((sub_folder) => {
                                if (response.data.Data.content_folders.indexOf(sub_folder.id) > -1) newSel.push(folder.id);
                            })
                    });
                setExpanded(newSel);
            }
        })
    }

    const isExpanded = (id) => {
        return expanded.indexOf(id) > -1
    }

    const isChecked = (id) => {
        return selected.indexOf(id) > -1;
    }

    const checkedToggle = (id) => {
        if (isChecked(id)) {
            setSelected((sel) => (sel.filter(sel_id => sel_id != id)))
        } else {
            setSelected((sel) => ([
                ...sel,
                id
            ]))
        }
    }

    const Save = () => {
        setSaving(true);
        API.SaveCaseFolderContent(props.content_id, selected).then(() => {
            loadFolders();
            setSaving(false);
        })
    }

    useEffect(() => {
        loadFolders();
    }, [])

    return (
        <>
            {showEdit !== false ? (
                <EditFolder
                    folder={{
                        ...showEdit,
                        parentFolder: showEdit.parent ? folders.folders.filter((folder) => (folder.id === showEdit.parent))[0] : '0'
                    }}
                    key={`EditFolder-${timeLoaded}`}
                    onSave={(data) => {
                        if (showEdit !== false && showEdit.id) {
                            API.EditCaseFolder(showEdit.id, data).then(() => {
                                setShowEdit(false);
                                loadFolders();
                            })
                        } else {
                            API.CreateCaseFolder(data).then(() => {
                                setShowEdit(false);
                                loadFolders();
                            })
                        }
                    }}
                    onClose={() => {
                        setShowEdit(false);
                    }}
                    onDelete={(id) => {
                        API.DeleteCaseFolder(id).then(() => {
                            setShowEdit(false);
                            loadFolders();
                        })
                    }}
                />
            ) : null}
            <Button
                icon={'folder-open'}
                onClick={() => {
                    setOpen(true)
                }}
            >
                Folders {folders.content_folders && folders.content_folders.length > 0 ? (<>&nbsp;<Tag minimal round style={{ float: 'right' }}>{folders.content_folders.length}</Tag></>) : null}
            </Button>
            {open && (
                <Dialog
                    isOpen={open}
                    onClose={() => { setOpen(false) }}
                    autoFocus={true}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    enforceFocus={true}
                    usePortal={true}
                    className={Classes.DARK}
                >
                    <div className={Classes.DIALOG_BODY}>
                        <Tree
                            onNodeDoubleClick={(node) => {
                                setShowEdit({
                                    id: node.id,
                                    parent: node.parent,
                                    title: node.original_label,
                                    description: node.description,
                                    photo_id: node.photo_id,
                                    file_path: node.file_path
                                });
                            }}
                            key={`Tree-${timeLoaded}`}
                            onNodeExpand={(node) => {
                                setExpanded((exp) => ([
                                    ...exp,
                                    node.id
                                ]))
                            }}
                            onNodeCollapse={(node) => {
                                setExpanded((exp) => (exp.filter(exp_id => exp_id != node.id)));
                            }}
                            contents={folders.folders.filter((folder) => (folder.parent == '0')).map((folder) => (
                                {
                                    id: folder.id,
                                    icon: folder.file_path && folder.file_path.length > 0 ? 'media' : "folder-open",
                                    label: folder.title,
                                    original_label: folder.title,
                                    isExpanded: true,
                                    hasCaret: false,
                                    node: folder.node,
                                    description: folder.description,
                                    photo_id: folder.photo_id,
                                    file_path: folder.file_path,
                                    childNodes: folders.folders.filter((sub_folder) => (sub_folder.parent == folder.id)).map((sub_folder) => (
                                        {
                                            id: sub_folder.id,
                                            label: (
                                                <Checkbox
                                                    checked={isChecked(sub_folder.id)}
                                                    label={sub_folder.title}
                                                    style={{ marginBottom: 0 }}
                                                    large
                                                    onChange={(e) => {
                                                        checkedToggle(sub_folder.id);
                                                    }}
                                                />
                                            ),
                                            original_label: sub_folder.title,
                                            hasCaret: false,
                                            parent: sub_folder.parent,
                                            description: sub_folder.description,
                                            photo_id: sub_folder.photo_id,
                                            file_path: sub_folder.file_path,
                                        }
                                    ))
                                }
                            ))}
                        />
                    </div>
                    <div className={Classes.DIALOG_FOOTER_FIXED}>
                        <div className={Classes.DIALOG_FOOTER_MAIN_SECTION}>
                            <Button
                                icon={'folder-new'}
                                onClick={() => {
                                    setShowEdit(true);
                                }}
                            >New folder</Button>
                        </div>
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <Button onClick={() => {
                                setOpen(false);
                                Save();
                            }} loading={saving} intent={'primary'}>Save</Button>
                            <Button onClick={() => { setOpen(false) }}>Close</Button>
                        </div>
                    </div>

                </Dialog>
            )}
        </>
    )

}