import React, { useState, useEffect } from 'react';

import { Classes, Spinner, Button, Card } from '@blueprintjs/core'

import API from '../Utilities/API';
import Search from '../Navigation/Search'
import Single from './Single';

import PasteUpload from '../Upload/Paste';

import Uploads from '../Utilities/Uploads';

export default (props) => {

    const [loaded, setLoaded] = useState(false);
    const [data, setData] = useState([]);
    const [query, setQuery] = useState({});
    const [start, setStart] = useState(0);

    const [uploading, setUploading] = useState(false);
    const [uploadTitle, setUploadTitle] = useState('')

    const getTitle = () => (uploadTitle);

    const UploadStatus = (e) => {
        if (e.type === 'uploadComplete') {
            let x = JSON.parse(e.file.upload.response);
            if (x.Data.file_id) {
                API.MoveUploadToGallery(x.Data.file_id, getTitle()).then(() => {
                    setStart(0);
                    setData([]);
                    setUploading(false);
                    setLoaded(false);
                });
            }
        } else {
            setUploading(true);
        }
    }

    useEffect(() => {
        console.log('Got new title', uploadTitle);
    }, [uploadTitle])

    useEffect(() => {
        if (loaded == false) {
            API.Photo({ ...query, start }).then((result) => {
                if (result && result.data && result.data.Data) {
                    setData([...data, ...result.data.Data]);
                    setLoaded(true);
                }
            });
        }
        Uploads.onChange((e) => {
            UploadStatus(e)
        }, 'MediaWindow');
    }, [loaded])

    return (
        <>
            <div className={Classes.DRAWER_HEADER}>
                <div style={{ width: '75%', margin: 'auto' }}>
                    <Search
                        hideLanguage
                        search={(country, language, keywords, query) => {
                            setData([]);
                            setStart(0);
                            setQuery({
                                country,
                                language,
                                keywords,
                                query
                            });
                            setLoaded(false);
                        }}
                        searchButtons={[]}
                    />
                </div>
            </div>
            <div className={Classes.DRAWER_BODY}>

                <div style={{
                    width: '75%',
                    margin: 'auto',
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap'
                }}>
                    {(loaded || data.length > 0) && (
                        <div style={{ width: '20%' }}>
                            <Card style={{ height: 375, textAlign: 'center' }}>
                                {uploading ? <Spinner /> : (
                                    <PasteUpload
                                        title
                                        noHide
                                        hide={() => {

                                        }}
                                        uploadTitle={(title) => {
                                            setUploadTitle(title);
                                        }}
                                    />
                                )}
                            </Card>
                        </div>
                    )}
                    {data.map((item, index) => (
                        <div style={{ width: '20%' }}>
                            <Single
                                data={item}
                                buttons={<>
                                    <Button icon={'plus'} text={'Add'} onClick={() => {
                                        if (props.addToCase) {
                                            if (window.confirm('Select photo?')) {
                                                props.addToCase(item.file_id, item.name, item);
                                            }
                                        }
                                    }} />
                                </>}
                            />
                        </div>
                    ))}
                    {!loaded && <div style={{ paddingTop: 20, paddingBottom: 20, width: '100%' }}><Spinner /></div>}
                    {loaded && data.length > 0 && (data.length % 100 == 0 || (data.length == 99 && start == 0)) ? (
                        <div style={{ paddingTop: 20, paddingBottom: 20, width: '100%' }}>
                            <Button large fill onClick={() => {
                                setStart(start + 100);
                                setLoaded(false);
                            }}>Load more</Button>
                        </div>
                    ) : null}
                </div>
            </div>
            <div className={Classes.DRAWER_FOOTER}>
                {loaded ? `Found ${data.length} results` : null}
            </div>
        </>
    )

}