import React from 'react';

import { Alignment, Button, Icon, Tag } from '@blueprintjs/core';
import U from '../Utilities/Utilities';
import { Tooltip2 } from '@blueprintjs/popover2';

class SingleCase extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            expanded: this.props.expanded
        }

        this.toggleExpanded = this.toggleExpanded.bind(this);
    }

    toggleExpanded() {
        this.props.setActiveCase && this.props.setActiveCase()
    }

    static getDerivedStateFromProps(props, state) {
        if (props.expanded !== state.expanded) {
            return {
                expanded: props.expanded
            };
        }
        return null;
    }

    getTitle() {

        if (this.props.case.notes && this.props.case.notes.length && this.props.case.notes[0].title) {
            return ' - ' + U.strip_tags(this.props.case.notes[0].title)
        }
        if (this.props.case.news && this.props.case.news.length > 0 && this.props.case.news[0].title && this.props.case.news[0].title != 'undefined') {
            return ' - ' + U.strip_tags(this.props.case.news[0].title)
        }

        return null;
    }

    render() {

        const hasPublishedNotes = this.props.case.notes && this.props.case.notes.length && this.props.case.notes.filter((note) => (note && note.publish)).length > 0;
        const isLastPublishedNote = this.props.case.notes && this.props.case.notes[0] && this.props.case.notes[0].publish;
        const publishedNotes = this.props.case.notes && this.props.case.notes.length && this.props.case.notes.filter((note) => (note && note.publish)).map((note) => (<div>{U.prettyStampFull(note.publish) + ' • ' + note.title}</div>));

        let publishIcon = 'circle';
        if (isLastPublishedNote) publishIcon = 'full-circle';
        else if (hasPublishedNotes) publishIcon = 'ring';

        let time = this.props.case.insert_datetime;
        if (this.props.case.last_update) time = this.props.case.last_update;
        time = U.prettyStamp(time);

        let color = null;
        if (this.props.active) color = '#182026';

        const charCount = this.props.case.text && this.props.case.text.length ? this.props.case.text.length : 0;

        const intent = this.props.case.alarm == '1' ? 'primary' : 'none';

        const folderList = this.props.case.folders ? this.props.case.folders.map((folder) => (<Tag fill intent={'primary'}>{`${folder.parentTitle} > ${folder.title}`}</Tag>)) : null;

        return (
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                marginBottom: 1
            }}>
                <div style={{
                    width: '70%'
                }}>
                    <Button
                        alignText={Alignment.LEFT}
                        fill
                        large
                        id={`SingleCase${this.props.case.id}`}
                        style={{ borderRadius: 0, boxShadow: 'none', border: 0, height: '100%', backgroundColor: color, display: 'flex', textDecoration: 'none' }}
                        onClick={this.toggleExpanded}
                        className={'singleGroupButton' + (this.props.expanded === false ? ' notexpanded' : ' expanded')}
                        intent={intent}
                    >
                        {this.props.expanded === false ? (
                            <div style={{ whiteSpace: 'nowrap' }}>
                                <h6 style={{ marginTop: '.5rem', marginBottom: '.5rem', fontSize: 12 }}>
                                    <span style={{ color: '#62D96B' }} className={'bp5-monospace-text'}>{time}</span>&nbsp;
                                    {!this.props.simple && (
                                        <>
                                            {this.props.case.shared_user && this.props.case.shared_user.length ? <>&nbsp;&nbsp;&nbsp;<Tooltip2 interactionKind={'hover'} content={this.props.case.shared_user}><Icon icon={'user'} intent={this.props.case.user_id == window.nd.User.id ? null : 'primary'} /></Tooltip2>&nbsp;&nbsp;&nbsp;&nbsp;</> : ''}
                                            <Tooltip2 content={publishedNotes} style={{ marginRight: 5 }}><Icon icon={publishIcon} intent={'primary'} /></Tooltip2>
                                        </>)}
                                    <span style={{ color: '#FFB366' }}>{this.props.case.countryData.name || this.props.case.country}</span>&nbsp;-&nbsp;
                                    {this.props.case.count}&nbsp;-&nbsp;
                                    {this.props.case.title}
                                </h6>
                            </div>
                        ) : (
                            <>
                                <div style={{ whiteSpace: 'nowrap' }}>
                                    <span style={{ color: '#62D96B' }} className={'bp5-monospace-text'}>{time}</span>&nbsp;&nbsp;
                                    {!this.props.simple && (
                                        <>
                                            {this.props.case.shared_users && this.props.case.shared_users.length ? <>&nbsp;<Tooltip2 interactionKind={'hover'} content={
                                                <>
                                                    {this.props.case.owner && this.props.case.owner.length ? <Tag fill style={{ marginBottom: 5 }} intent={'primary'}>{this.props.case.owner}</Tag> : null}
                                                    {this.props.case.shared_users.filter((u) => (u !== this.props.case.owner)).map((u) => (<Tag fill style={{ marginBottom: 5 }}>{u}</Tag>))}
                                                </>
                                            }>
                                                <Icon icon={'user'} intent={this.props.case.user_id == window.nd.User.id ? null : 'primary'} />
                                            </Tooltip2>&nbsp;&nbsp;&nbsp;</> : ''}
                                            <span style={{ marginRight: 10, marginLeft: 5 }}><Tooltip2 hoverOpenDelay={0} content={publishedNotes}><Icon icon={publishIcon} intent={'primary'} /></Tooltip2></span>
                                            {this.props.case.verified && (<span style={{ marginRight: 10, marginLeft: 5 }}><Icon icon={'tick-circle'} intent={'success'} /></span>)}

                                            {window.nd.User && window.nd.User.permissions && window.nd.User.permissions.case_folders && (
                                                <span style={{ marginLeft: 10, marginRight: 10 }}><Tooltip2 content={this.props.case.folders.length > 0 ? folderList : null}><Icon icon={this.props.case.folders.length > 0 ? 'folder-open' : 'folder-close'} intent={this.props.case.folders.length > 0 ? 'primary' : 'none'} /></Tooltip2></span>
                                            )}

                                        </>)}
                                    <span style={{ color: '#FFB366', marginRight: 7 }}>{this.props.case.countryData.name || this.props.case.country}&nbsp;&raquo;&nbsp;
                                        {this.props.case.title}
                                    </span>
                                </div>
                            </>
                        )}
                        <div style={{ whiteSpace: 'nowrap', flexGrow: 1, overflowX: 'clip', textOverflow: 'ellipsis', minWidth: 0, paddingRight: 150 }} className={'articleText'}>
                            {this.getTitle()}
                        </div>
                    </Button>
                </div>
                <div style={{
                    width: '30%'
                }}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginLeft: 'auto',
                    }}>
                        <div style={{
                            width: '40%'
                        }}>
                            <Button intent={intent} alignText={Alignment.CENTER} style={{ textAlign: 'center', borderRadius: 0, boxShadow: 'none', backgroundColor: color, display: 'flex', textDecoration: 'none', height: '100%', border: 0 }} className={'singleGroupButton' + (this.props.expanded === false ? ' notexpanded' : ' expanded')} fill large>{this.props.case.categoryData?.name} </Button>
                        </div>
                        <div style={{
                            width: this.props.simple ? '60%' : '40%'
                        }}>
                            <Button intent={intent} alignText={Alignment.CENTER} style={{ textAlign: 'center', borderRadius: 0, boxShadow: 'none', backgroundColor: color, display: 'flex', textDecoration: 'none', height: '100%', border: 0 }} className={'singleGroupButton' + (this.props.expanded === false ? ' notexpanded' : ' expanded')} fill large>{this.props.case.typeData?.title} </Button>
                        </div>
                        {!this.props.simple && (
                            <div style={{
                                width: '20%'
                            }}>
                                <Button intent={intent} alignText={Alignment.CENTER} style={{ textAlign: 'center', borderRadius: 0, boxShadow: 'none', backgroundColor: color, display: 'flex', textDecoration: 'none', height: '100%', border: 0 }} className={'singleGroupButton' + (this.props.expanded === false ? ' notexpanded' : ' expanded')} fill large>{this.props.case.notes?.length || 0} {this.props.case.saved_searches && this.props.case.saved_searches.length > 0 ? (<span title={this.props.case.saved_searches.map((ss) => (ss.title)).join('\n')}>🔍</span>) : null}</Button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        )
    }
}

export default SingleCase;