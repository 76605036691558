import { Alignment, Button, Navbar, NavbarDivider, NavbarGroup, NavbarHeading, Tree } from '@blueprintjs/core';
import Cases from './Cases'
import API from '../Utilities/API';

import EditFolder from './EditFolder';

import { useState, useEffect } from 'react'

export default function Page() {

    const [folders, setFolders] = useState([])
    const [timeLoaded, setTimeLoaded] = useState(0)
    const [showEdit, setShowEdit] = useState(false);

    const loadFolders = () => {
        API.CasesFolders().then((response) => {
            setFolders(response.data.Data.folders);
            setTimeLoaded(new Date().getTime())
        })
    }

    useEffect(() => {
        loadFolders();
    }, [])

    return (
        <>
            <div style={{
                width: '100%',
                height: 50
            }}>
                {showEdit !== false ? (
                    <EditFolder
                        folder={{
                            ...showEdit,
                            parentFolder: showEdit.parent ? folders.filter((folder) => (folder.id === showEdit.parent))[0] : '0'
                        }}
                        key={`EditFolder-${timeLoaded}`}
                        onSave={(data) => {
                            if (showEdit !== false && showEdit.id) {
                                API.EditCaseFolder(showEdit.id, data).then(() => {
                                    setShowEdit(false);
                                    loadFolders();
                                })
                            } else {
                                API.CreateCaseFolder(data).then(() => {
                                    setShowEdit(false);
                                    loadFolders();
                                })
                            }
                        }}
                        onClose={() => {
                            setShowEdit(false);
                        }}
                        onDelete={(id) => {
                            API.DeleteCaseFolder(id).then(() => {
                                setShowEdit(false);
                                loadFolders();
                            })
                        }}
                    />
                ) : null}
                <Navbar style={{ position: 'sticky', top: 0, paddingLeft: 0, display: 'flex' }}>
                    <NavbarGroup>
                        <NavbarHeading>
                            Case Folders
                        </NavbarHeading>
                        <NavbarDivider />
                    </NavbarGroup>
                    <NavbarGroup>
                        <Button
                            icon={'folder-new'}
                            onClick={() => {
                                setShowEdit(true);
                            }}
                        >New folder</Button>
                    </NavbarGroup>
                </Navbar>
            </div>
            <div style={{
                width: '100%',
                height: '100%',
                overflow: 'hidden',
                display: 'flex',
                flexDirection: 'row'
            }}>
                <div style={{
                    flexBasis: 300,
                    flexGrow: 0,
                    flexShrink: 0,
                    height: '100%',
                    overflow: 'auto'
                }}>
                    <Tree
                        key={`Tree-${timeLoaded}`}
                        onNodeDoubleClick={(node) => {
                            console.log('Double click', node);
                            setShowEdit({
                                id: node.id,
                                parent: node.parent,
                                title: node.label,
                                description: node.description,
                                photo_id: node.photo_id,
                                file_path: node.file_path
                            });
                        }}
                        contents={folders.filter((folder) => (folder.parent == '0')).map((folder) => (
                            {
                                id: folder.id,
                                icon: folder.file_path && folder.file_path.length > 0 ? 'media' : "folder-open",
                                label: folder.title,
                                isExpanded: true,
                                hasCaret: false,
                                node: folder.node,
                                description: folder.description,
                                photo_id: folder.photo_id,
                                file_path: folder.file_path,
                                childNodes: folders.filter((sub_folder) => (sub_folder.parent == folder.id)).map((sub_folder) => (
                                    {
                                        id: sub_folder.id,
                                        icon: sub_folder.file_path && sub_folder.file_path.length > 0 ? 'media' : 'document',
                                        label: sub_folder.title,
                                        hasCaret: false,
                                        parent: sub_folder.parent,
                                        description: sub_folder.description,
                                        photo_id: sub_folder.photo_id,
                                        file_path: sub_folder.file_path,
                                    }
                                ))
                            }
                        ))}
                    />
                </div>
                <div style={{
                    flexGrow: 1
                }}>
                    <Cases
                        popup
                        returnTo={'/cases_folders'}
                    />
                </div>
            </div>
        </>
    )

}