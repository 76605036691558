import React from 'react';

import TabPage from './TabPage';
import Cases from '../Cases/Cases';

import { Button, Icon, Tag, Tooltip } from '@blueprintjs/core'

import Drafts from '../Draft/List';
import API from '../Utilities/API';
import UploadButton from '../Upload/Button';
import UploadList from '../Upload/List';

import NotificationsButton from '../Notifications/Button';
import NotificationsList from '../Notifications/List';

import TravelSecurity from '../TravelSecurity/TravelSecurity'

import Report from '../Report/Report'

import Wrapped from '../Utilities/Wrapped';

import LogoutButton from '../Main/Logout';

import NotesButton from '../Notes/Button';
import NotesList from '../Notes/List';

import SSButton from '../SavedSearch/Button';
import SSNews from '../SavedSearch/News';

import ProtectAccount from '../SSO/Protect';
import BioButton from '../Utilities/BioButton';
import SingleArticle from '../Intelligence/SingleArticle';

import DraftButton from '../Draft/Button';
import AIButton from '../AI/Button';

import IntelligenceNews from '../Intelligence/News'
import Sanctions from '../Sanctions/Sanctions';

import KeywordRequestButton from '../Keywords/RequestButton';
import LogoutPage from '../Logout/Page';

import CaseFolders from '../Cases/Folders'

import {
    BrowserRouter,
    Routes,
    Route,
    Link,
    Navigate,
    useParams
} from "react-router-dom";

class MainPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            barWidth: 50,
            DRAFT: {
                data: [],
                datetime: 0
            },
            draftLoaded: false,
            loadKey: 0
        }

        this.addToDraft = this.addToDraft.bind(this);
        this.removeFromDraft = this.removeFromDraft.bind(this);
        this.isInDraft = this.isInDraft.bind(this);

        this.pages = {
            'research': {
                icon: 'panel-stats',
                title: 'News',
                component: <TabPage
                    addToDraft={this.addToDraft}
                    removeFromDraft={this.removeFromDraft}
                    isInDraft={this.isInDraft}
                    {...this.props}
                />,
                key: new Date().getTime()
            },
            'intelligence': {
                title: 'Intelligence',
                icon: 'geosearch',
                component: <IntelligenceNews />
            },
            'savedsearch': {
                title: 'Saved Search',
                buttonComponent: <SSButton large onClick={() => {
                    this.setPage('savedsearch')
                }} />,
                component: <SSNews
                    addToDraft={this.addToDraft}
                    removeFromDraft={this.removeFromDraft}
                    isInDraft={this.isInDraft}
                />
            },
            'report': {
                icon: 'book',
                title: 'Report',
                component: <Report />
            },
            'travel': {
                icon: 'airplane',
                title: 'Travel Security',
                component: <TravelSecurity />
            },
            'sanctions': {
                icon: 'database',
                title: 'Sanctions',
                component: <Sanctions />
            },
            'cases': {
                icon: 'box',
                title: 'Research',
                component: <Cases />,
                key: new Date().getTime()
            },
            'cases_folders': {
                title: 'Folders',
                component: <CaseFolders />,
                buttonComponent: () => (null)
            },
            'draft': {
                skipRoute: true
            },
            'upload': {
                title: 'Uploads',
                buttonComponent: <Link to={'/upload'}><UploadButton /></Link>,
                component: <UploadList />,
                hideTooltip: true
            },
            'notifications': {
                buttonComponent: <NotificationsButton />,
                component: <NotificationsList />,
                skipRoute: true
            },
            'protect': {
                buttonComponent: <ProtectAccount />,
                skipRoute: true
            },
            'incoming': {
                buttonComponent: <KeywordRequestButton />,
                skipRoute: true
            },
            'ai': {
                buttonComponent: <AIButton />,
                skipRoute: true
            },
            'notes': {
                buttonComponent: <Link to={'/notes'}><NotesButton /></Link>,
                component: <NotesList />
            },
            'logout': {
                buttonComponent: <LogoutButton />,
                component: <LogoutPage />,
                skipRoute: false
            }
        }
        let pathID = window.location.pathname.split('/')[1];
        if (this.pages[pathID]) this.state.pageID = pathID;
        else this.state.pageID = Object.keys(this.pages)[0];

        this.renderPage = this.renderPage.bind(this);
        this.loadData = this.loadData.bind(this);
        this.loadDrafts = this.loadDrafts.bind(this);

        this.removeDrafts = this.removeDrafts.bind(this);
    }

    componentDidMount() {
        if (!this.state.draftLoaded) {
            this.loadData();
        }
    }

    loadData() {
        this.setState({
            draftLoaded: false
        }, () => {
            this.loadDrafts();
        })
    }

    loadDrafts() {
        API.Draft().then((response) => {
            this.setState({
                DRAFT: response.data.Data,
                draftLoaded: true
            });
        });
    }

    addToDraft(x) {
        let D = this.state.DRAFT;
        D.data.push(x);
        D.datetime = new Date().getTime();
        this.setState({
            DRAFT: D
        }, () => {
            API.SaveDraft(this.state.DRAFT).then(() => {
                this.loadDrafts();
            });
        });
    }

    isInDraft(x) {
        if (x.id) {
            return this.state.DRAFT.data.filter((d) => (d.id == x.id)).length > 0;
        }
        else if (x.group && x.group.id) {
            return this.state.DRAFT.data.filter((d) => (d.group && d.group.id == x.group.id)).length > 0;
        }
        return false;
    }

    removeFromDraft(x) {
        let nd = this.state.DRAFT;
        nd.datetime = new Date().getTime();
        if (x.id) {
            nd.data = this.state.DRAFT.data.filter((d) => (d.id != x.id));
        }
        else if (x.group && x.group.id) {
            nd.data = this.state.DRAFT.data.filter((d) => (d.group && d.group.id != x.group.id));
        }

        this.setState({
            DRAFT: nd
        }, () => {
            API.SaveDraft(this.state.DRAFT).then(() => {
                this.loadDrafts();
            });
        })
    }

    removeDrafts(x) {
        if (window.confirm('Remove selected items from draft list?')) {
            let ids = x.map((xs) => (xs.group.id));
            let nd = this.state.DRAFT;
            nd.datetime = new Date().getTime();
            nd.data = this.state.DRAFT.data.filter((d) => (ids.indexOf(d.group.id) === -1));
            this.setState({
                DRAFT: nd
            }, () => {
                API.SaveDraft(this.state.DRAFT).then(() => {
                    this.loadDrafts();
                });
            })
        }
    }

    renderPage() {
        return (
            <Routes>
                <Route exact path="/" element={<Navigate replace to={`/${Object.keys(this.pages)[0]}`} />} />
                {
                    Object.keys(this.pages).map((pageID) => (
                        !this.pages[pageID].skipRoute ? (
                            <Route key={`route-${pageID}`} path={`/${pageID}/*`} element=
                                {pageID === 'draft' ? <Drafts
                                    key={this.state.DRAFT.datetime}
                                    DRAFT={this.state.DRAFT}
                                    addToDraft={this.addToDraft}
                                    isInDraft={this.isInDraft}
                                    removeFromDraft={this.removeFromDraft}
                                    removeDrafts={this.removeDrafts}
                                /> : <Wrapped keyParam={'*'} component={this.pages[pageID].component} additionalProps={{ key: this.state.loadKey, savePages: this.props.savePages }} />}
                            ></Route>
                        ) : null
                    ))
                }
            </Routes>
        )
    }

    setPage(page) {
        this.setState({
            pageID: page,
            loadKey: new Date().getTime()
        })
    }

    render() {
        return (
            <>
                <BrowserRouter>
                    <div style={{ width: '100vw', height: '100vh' }}>
                        <div style={{
                            width: this.state.barWidth,
                            height: '100vh',
                            background: '#293742',
                            float: 'left',
                            boxShadow: '0 0 0 1px rgb(16 22 26 / 20%), 0 0 0 rgb(16 22 26 / 0%), 0 1px 1px rgb(16 22 26 / 40%)'
                        }}>
                            <div style={{
                                boxShadow: '0 0 0 1px rgb(16 22 26 / 20%), 0 0 0 rgb(16 22 26 / 0%), 0 1px 1px rgb(16 22 26 / 40%)',
                                width: '100%',
                                height: 50,
                                backgroundColor: '#394b59',
                                paddingLeft: 12,
                                paddingTop: 12
                            }}>
                                <Icon
                                    icon={'predictive-analysis'}
                                    size={24}
                                />
                            </div>
                            {Object.keys(this.pages).map((pageID) => (
                                pageID != 'draft' ? (
                                    <Tooltip key={`tooltip-${pageID}`} content={this.pages[pageID].title} hoverOpenDelay={this.pages[pageID].hideTooltip ? 999999 : 0}>
                                        {this.pages[pageID].buttonComponent ? React.cloneElement(this.pages[pageID].buttonComponent, {
                                            onClick: this.setPage.bind(this, pageID),
                                            active: this.state.pageID === pageID ? true : null,
                                            className: 'btn-no-padding'
                                        }) : (
                                            <Link to={`/${pageID}`}>
                                                <Button
                                                    icon={this.pages[pageID].icon}
                                                    large
                                                    active={this.state.pageID == pageID ? true : null}
                                                    style={{ marginLeft: 4, marginTop: 5 }}
                                                    onClick={this.setPage.bind(this, pageID)}
                                                    intent={this.pages[pageID].intent}
                                                    key={this.pages[pageID].key}
                                                >{this.pages[pageID].text}</Button>
                                            </Link>
                                        )}
                                    </Tooltip>
                                ) : (
                                    <DraftButton
                                        draft={this.state.DRAFT}
                                        loaded={this.state.draftLoaded}
                                        active={this.state.pageID == pageID ? true : null}
                                        key={this.state.DRAFT.datetime}
                                    >
                                        <Drafts
                                            key={this.state.DRAFT.datetime}
                                            DRAFT={this.state.DRAFT}
                                            addToDraft={this.addToDraft}
                                            isInDraft={this.isInDraft}
                                            removeFromDraft={this.removeFromDraft}
                                            removeDrafts={this.removeDrafts}
                                            isPopup
                                        />
                                    </DraftButton>
                                )
                            ))}
                        </div>
                        <div style={{ width: `calc(100vw - ${this.state.barWidth}px)`, height: '100vh', overflow: 'hidden', float: 'left' }}>
                            {this.state.draftLoaded ? this.renderPage() : null}
                        </div>
                    </div>
                </BrowserRouter>
            </>
        )
    }

}
export default MainPage;