import { useState } from 'react';

import ReactQuill from 'react-quill';
import '../../Quill.css';

import { Button, ControlGroup, Navbar, NavbarGroup, Spinner, Tag, Alignment } from '@blueprintjs/core';

import ContentNotes from '../ContentNotes/ContentNotes';

import LinksButton from '../Links/Button';
import ShareButton from '../Share/Button';

import ArchiveButton from './ArchiveButton';
import FinishButton from './FinishButton';
import NewCase from './NewCase';

import API from '../Utilities/API';
import { Tooltip2 } from "@blueprintjs/popover2";

import CaseHistory from './History'
import FoldersButton from './FoldersButton';

let saveTimeout = 0;

export default ({ saveText, text, onChange, doReload, caseData, case_id, onNotesLoad, showNotes, editorLoaded, reloadCases, analysisTitle, onCloseCase, onUnarchiveCase, noteKey, setCaseData }) => {

    const [editorText, setEditorText] = useState(text || '');
    const [sourceType, setSourceType] = useState(showNotes ? 'notes' : 'editor');
    const [loadKey, setLoadKey] = useState(0);
    const [noteNumber, setNoteNumber] = useState(0);
    const [isEditorLoaded, setEditorLoaded] = useState(editorLoaded);
    const [titleText, setTitleText] = useState(analysisTitle || '');
    const [sending, setSending] = useState(false);

    return (
        <>
            <Navbar style={{ padding: 10, paddingTop: 0, paddingBottom: 7, marginTop: 1, boxShadow: 'none' }}>
                <NavbarGroup style={{
                    width: '100%'
                }}>
                    <ControlGroup style={{ marginRight: 5 }}>
                        <Button active={sourceType == 'notes' ? true : undefined} onClick={() => { setSourceType('notes') }}>
                            Notes <Tag minimal round>{noteNumber || 0}</Tag>
                        </Button>
                        <Button active={sourceType == 'editor' ? true : undefined} onClick={() => { setSourceType('editor') }}>
                            Editor
                        </Button>
                    </ControlGroup>
                    <ControlGroup style={{ marginRight: 5 }}>
                        <ShareButton key={`ButtonShare-${case_id}`} mark_id={caseData.user_id} content_id={`Case:${case_id}`} onShare={reloadCases} />
                        {window.nd.User && window.nd.User.permissions && window.nd.User.permissions.case_folders && (<FoldersButton key={`FoldersButton-${case_id}`} content_id={case_id} />)}
                    </ControlGroup>
                    <ControlGroup align={Alignment.RIGHT} style={{
                        flexGrow: 1,
                        flexDirection: 'row-reverse'
                    }}>

                        {caseData.active == '1' ? (
                            <FinishButton
                                icon={'stop'}
                                case_id={caseData.id}
                                onCloseCase={onCloseCase}
                            />
                        ) : (

                            caseData.archive == '0' ? (
                                <Button
                                    icon={'refresh'}
                                    onClick={() => {
                                        if (window.confirm('Reactivate case?')) {
                                            API.OpenCase(caseData.id).then(() => {
                                                onCloseCase && onCloseCase(false);
                                            });
                                        }
                                    }}
                                >
                                    Reactivate
                                </Button>
                            ) : null

                        )}

                        <Tooltip2 content={'Create assignment from case'}>
                            <Button
                                icon={'annotation'}
                                intent={'success'}
                                loading={sending}
                                onClick={() => {
                                    if (window.confirm('Create assignment from entire case?')) {
                                        setSending(true);
                                        API.AssignmentFromCase(caseData.id).then((response) => {
                                            setSending(false);
                                            alert('Assignment created');
                                        });
                                    }
                                }}
                            />
                        </Tooltip2>

                        {caseData.archive == '0' ? (
                            <Tooltip2 content={'Archive case'}>
                                <ArchiveButton
                                    icon={'archive'}
                                    case_id={caseData.id}
                                    onCloseCase={onCloseCase}
                                />
                            </Tooltip2>
                        ) : (
                            <Tooltip2 content={'Unarchive case'}>
                                <Button
                                    icon={'archive'}
                                    active
                                    onClick={() => {
                                        if (window.confirm('Unarchive case?')) {
                                            API.UnarchiveCase(caseData.id).then(() => {
                                                onUnarchiveCase && onUnarchiveCase();
                                            });
                                        }
                                    }}
                                >
                                    Restore
                                </Button>
                            </Tooltip2>
                        )}


                        <NewCase
                            icon={'edit'}
                            title={caseData.title}
                            country={caseData.country}
                            id={caseData.id}
                            saveIcon={'tick'}
                            saveText={'Save'}
                            loadCases={reloadCases}
                            case={caseData}
                            key={`EditCase-${caseData.id}`}
                            hideText
                            onSave={(data) => {
                                setCaseData(data);
                            }}
                        />
                        <LinksButton key={`ButtonLinks-${case_id}`} content_id={`Case:${case_id}`} reload={reloadCases} hideText />

                        <Button active={sourceType == 'history' ? true : undefined} onClick={() => { setSourceType('history') }} icon={'history'} />

                        <Button icon={'issue'} intent={caseData.alarm == '1' ? 'primary' : 'none'} active={caseData.alarm == '1'} onClick={() => {
                            if (window.confirm('Toggle alarm?')) {
                                API.ToggleCaseAlarm(case_id).then(() => {
                                    doReload && doReload();
                                })
                            }
                        }} />

                        <Button icon={'tick'} intent={caseData.verified ? 'success' : 'none'} active={caseData.verified} onClick={() => {
                            if (window.confirm('Toggle verification?')) {
                                API.ToggleCaseVerified(case_id).then(() => {
                                    doReload && doReload();
                                })
                            }
                        }} />



                    </ControlGroup>
                </NavbarGroup>
            </Navbar >

            <div style={{ display: (sourceType == 'notes' ? 'block' : 'none'), padding: 10, flex: 1, overflow: 'auto' }}>
                <ContentNotes
                    case_id={case_id}
                    key={`contentnotes-${loadKey}-${noteKey}`}
                    setNumber={(number) => {
                        setNoteNumber(number);
                    }}
                    doReload={doReload}
                    onLoad={(notes) => {
                        onNotesLoad && onNotesLoad(notes);
                    }}
                />
            </div>
            <div style={{ display: (sourceType == 'editor' ? 'flex' : 'none'), flex: 1, overflow: 'hidden', flexDirection: 'column' }}>
                <div style={{ flexGrow: 0, flexShrink: 0 }}>
                    <input
                        class="bp5-input bp5-fill bp5-large"
                        type="text"
                        placeholder={"Analysis title"}
                        dir="auto"
                        defaultValue={titleText}
                        onChange={(e) => {
                            setTitleText(e.target.value);
                            clearTimeout(saveTimeout);
                            saveTimeout = setTimeout(() => {
                                saveText && saveText(editorText, e.target.value);
                            }, 500)
                        }}
                    />
                </div>
                <div style={{ flex: 1, flexGrow: 1 }}>
                    {
                        isEditorLoaded ? <Spinner /> : (
                            <ReactQuill
                                theme={'snow'}
                                value={editorText}
                                onChange={(val) => {
                                    setEditorText(val);
                                    clearTimeout(saveTimeout);
                                    saveTimeout = setTimeout(() => {
                                        saveText && saveText(val, titleText);
                                    }, 500)
                                }}
                            />
                        )
                    }
                </div>
            </div>
            <div style={{ display: (sourceType == 'history' ? 'block' : 'none'), padding: 10, flex: 1, overflow: 'auto' }}>
                <CaseHistory case_id={caseData.id} key={`ButtonShare-${case_id}-${loadKey}`} />
            </div>
        </>
    )

}