const Utilities = {

    prettyStamp: function (stamp) {
        if (stamp && stamp.length) {
            let x = stamp.replace(/ /, 'T').split('T');
            x = x[1].split(':');
            return x[0] + ':' + x[1];
        }
        else return stamp;
    },

    prettyStampFull: function (stamp) {
        if (stamp && stamp.length) {
            let x = stamp.replace(/ /, 'T').split('T');
            let y = x[0].split('-');
            x = x[1].split(':');

            let pre = ' ';
            if (y[0] != new Date().getFullYear().toString()) pre = y[0] + '. ';

            return y[2] + '.' + y[1] + '.' + pre + x[0] + ':' + x[1];
        }
        else return stamp;
    },

    prettyStampYear: function (stamp) {
        if (stamp && stamp.length) {
            let x = stamp.replace(/ /, 'T').split('T');
            let y = x[0].split('-');
            x = x[1].split(':');
            return y[2] + '.' + y[1] + '.' + y[0] + '. ' + x[0] + ':' + x[1];
        }
        else return stamp;
    },

    date: function (stamp, long) {
        if (stamp && stamp.length) {
            let x = stamp.replace(/ /, 'T').split('T');
            x = x[0].split('-');
            return x[2] + '.' + x[1] + (long === true || x[0] != new Date().getFullYear() ? '.' + x[0] : '');
        }
        else return stamp;
    },

    keywordColor: function (k) {
        const colors = {
            'Person': '#D99E0B',
            'City': '#29A634',
            'Country': '#2965CC',
            'Company': '#D13913',
            'Other': '#8F398F',
            'Generic': '#00B3A4',
            'Political party': '#DB2C6F',
            'Institution': '#9BBF30'
        }
        const def = '#96622D';

        return colors[k] ? colors[k] : def;
    },

    highlightKeyword: function (id) {
        window.document.querySelectorAll(`span[data-keyword='${id}']`).forEach(function (e) { e.classList.add('highlightKeyword') });
    },

    unhighlightKeywords: function () {
        if (window.document.querySelectorAll(".highlightKeyword")) {
            window.document.querySelectorAll(".highlightKeyword").forEach(function (e) { e.classList.remove('highlightKeyword') });
        }
    },

    strip_tags: function (html) {
        let tmp = document.createElement("DIV");
        tmp.innerHTML = html;
        return tmp.textContent || tmp.innerText || "";
    },

    showKeywordMarkers: function () {
        if (!window.document.querySelector('.active-page .newsScroller')) return false;
        const totalHeight = window.document.querySelector('.active-page .newsScroller').scrollHeight;
        window.document.querySelector('.active-page .scroll-marker').innerHTML = '';
        window.document.querySelectorAll('.highlightKeyword').forEach((el) => {
            let percent = Math.round((el.offsetTop / totalHeight) * 100);
            window.document.querySelector('.active-page .scroll-marker').innerHTML += `<div style="top: calc(${percent}% - 5px);"></div>`;
        });
    },

    removeKeywordMarkers: function () {
        if (window.document.querySelector('.active-page .scroll-marker')) {
            window.document.querySelector('.active-page .scroll-marker').innerHTML = '';
        }
    },

    decodeEntities: function (html) {
        var txt = window.document.createElement("textarea");
        txt.innerHTML = html;
        return txt.value;
    },

    formatNumber: function (number) {
        return number.toLocaleString(undefined, { maximumFractionDigits: 0 });
    },

    GroupFromArticle(article) {
        return {
            countries: [{
                country_id: article?._source?.countryData?.id,
                datetime: article?._source?.timestamp,
                group_id: `Intelligence:${article._id}`
            }],
            group: {
                created: article?._source?.timestamp,
                updated: article?._source?.timestamp,
                id: `Intelligence:${article._id}`,
                news_count: 1,
                news_id: article?._id,
                percent: 100,
                search_id: 0,
                seen: false
            },
            news: [
                article._source
            ],
            search: {
                id: `Intelligence:${article?._id}`,
                page: 'Intelligence',
                title: 'Intelligence',
                user_id: false
            }
        }
    },

    iconFromType(type) {
        type = type.toLowerCase();
        if (type === 'person') return 'person';
        else if (type === 'company') return 'office'
        else if (type === 'political party') return 'people';
        else if (type === 'organization') return 'diagram-tree';
        else if (type === 'city') return 'map-marker';
        else if (type === 'country') return 'globe';
        else if (type === 'institution') return 'globe-network';
        else if (type === 'concept') return 'cube';
        else return 'tag';
    },

    PageTitle(page) {
        let title = '';
        let icon = '';
        if (page.title === false) {
            if (page.filter.selectedKeywords.length) {
                title = page.filter.selectedKeywords[0].title;
                icon = Utilities.iconFromType(page.filter.selectedKeywords[0].calculated_type);
            } else if (page.filter.selectedCountry.id) {
                title = page.filter.selectedCountry.name.replace(/\&nbsp;/g, '');
                icon = 'globe';
            } else if (page.filter.selectedLanguage.id) {
                title = page.filter.selectedLanguage.name;
                icon = 'translate';
            }
        } else title = page.title;

        return {
            icon: icon,
            title: title
        }
    },

    startsWith(str, prefix) {
        let text = str.toLowerCase();
        text = text.replace(/&nbsp;/g, '')
        text = text.trim();
        return text.startsWith(prefix.toLowerCase());
    }

}

export default Utilities;